import React from "react";
import { Box, Typography, List, ListItem, ListItemText } from "@mui/material";
import Navbar from "./Navbar";
import HomepageFooter from "./HomepageFooter";

function PrivacyPolicy() {
  return (
    <>
      <Navbar />
      <Box
        sx={{
          backgroundColor: "primary",
          color: "white",
          minHeight: "100vh",
          padding: { xs: 3, sm: 4, md: 6 },
        }}
      >
        <Typography
          variant="h3"
          sx={{
            fontWeight: 600,
            fontSize: { xs: "1.8rem", sm: "2.4rem", md: "2.8rem" },
            textAlign: "left",
            marginBottom: 3,
          }}
        >
          Privacy Policy
        </Typography>

        {/* Section 1 */}
        <Typography
          variant="h4"
          sx={{
            fontWeight: 500,
            fontSize: { xs: "1.5rem", sm: "1.8rem", md: "2rem" },
            marginBottom: 2,
          }}
        >
          1. Introduction
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 4 }}>
          Welcome to AngelicDove. We are committed to protecting your privacy.
          This Privacy Policy explains how we collect, use, and share your
          personal information when you use our website and services, including
          generating videos using your uploaded pictures, selected songs, and
          backgrounds. By using our website, you agree to the terms of this
          Privacy Policy. If you do not agree, please do not use our services.
        </Typography>

        {/* Section 2 */}
        <Typography
          variant="h4"
          sx={{
            fontWeight: 500,
            fontSize: { xs: "1.5rem", sm: "1.8rem", md: "2rem" },
            marginBottom: 2,
          }}
        >
          2. Information We Collect
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          We collect the following types of information to provide and improve
          our services:
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="a. Personal Information" />
          </ListItem>
          <Typography variant="body2" sx={{ marginLeft: 3, marginBottom: 2 }}>
            • Account Information: When you create an account, we may collect
            your name, email address, and payment information.
            <br />
            • Uploaded Media: Pictures, videos, and other media files you upload
            to create your custom video content.
            <br />• Payment Details: Payment information is collected securely
            through our payment gateway for processing transactions. We do not
            store your credit card details.
          </Typography>
          <ListItem>
            <ListItemText primary="b. Usage Data" />
          </ListItem>
          <Typography variant="body2" sx={{ marginLeft: 3, marginBottom: 4 }}>
            • Website Activity: Information about your interactions with our
            website, including pages visited, video creation activities, and
            download history.
            <br />• Device Information: We may collect information about your
            device, such as IP address, browser type, and operating system.
          </Typography>
        </List>

        {/* Section 3 */}
        <Typography
          variant="h4"
          sx={{
            fontWeight: 500,
            fontSize: { xs: "1.5rem", sm: "1.8rem", md: "2rem" },
            marginBottom: 2,
          }}
        >
          3. How We Use Your Information
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 4 }}>
          We use your information for the following purposes:
          <br />
          • To generate and deliver custom videos based on your uploads.
          <br />
          • To process your payment transactions.
          <br />
          • To improve our website, services, and user experience.
          <br />
          • To communicate with you about updates, promotions, and customer
          support.
          <br />• To comply with legal obligations and enforce our Terms of
          Service.
        </Typography>

        {/* Section 4 */}
        <Typography
          variant="h4"
          sx={{
            fontWeight: 500,
            fontSize: { xs: "1.5rem", sm: "1.8rem", md: "2rem" },
            marginBottom: 2,
          }}
        >
          4. How We Share Your Information
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 4 }}>
          We do not sell or rent your personal information to third parties.
          However, we may share your information in the following circumstances:
          <br />
          • Service Providers: We may share your information with third-party
          service providers (e.g., payment processors, cloud storage providers)
          to help us deliver our services.
          <br />• Legal Compliance: We may disclose your information if required
          by law or to protect our rights and safety, as well as the rights and
          safety of our users.
        </Typography>

        {/* Section 5 */}
        <Typography
          variant="h4"
          sx={{
            fontWeight: 500,
            fontSize: { xs: "1.5rem", sm: "1.8rem", md: "2rem" },
            marginBottom: 2,
          }}
        >
          5. Security of Your Information
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 4 }}>
          We take reasonable measures to protect your information from
          unauthorized access, loss, misuse, or alteration. However, no method
          of transmission over the internet or electronic storage is completely
          secure. While we strive to use commercially acceptable means to
          protect your information, we cannot guarantee its absolute security.
        </Typography>

        {/* Section 6 */}
        <Typography
          variant="h4"
          sx={{
            fontWeight: 500,
            fontSize: { xs: "1.5rem", sm: "1.8rem", md: "2rem" },
            marginBottom: 2,
          }}
        >
          6. Your Rights
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 4 }}>
          Depending on your location, you may have certain rights regarding your
          personal information, including:
          <br />
          • Access: You can request access to the personal information we hold
          about you.
          <br />
          • Correction: You can request corrections to inaccurate or incomplete
          information.
          <br />
          • Deletion: You can request the deletion of your personal information
          where applicable.
          <br />• Opt-Out: You can opt out of marketing communications or
          withdraw consent where applicable.
        </Typography>

        {/* Section 7 */}
        <Typography
          variant="h4"
          sx={{
            fontWeight: 500,
            fontSize: { xs: "1.5rem", sm: "1.8rem", md: "2rem" },
            marginBottom: 2,
          }}
        >
          7. Children's Privacy
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 4 }}>
          Our website and services are not directed at children under the age of
          13. We do not knowingly collect personal information from children
          under 13. If we become aware that we have inadvertently collected
          information from a child under 13, we will take steps to delete such
          information promptly.
        </Typography>

        {/* Section 8 */}
        <Typography
          variant="h4"
          sx={{
            fontWeight: 500,
            fontSize: { xs: "1.5rem", sm: "1.8rem", md: "2rem" },
            marginBottom: 2,
          }}
        >
          8. Changes to This Privacy Policy
        </Typography>
        <Typography variant="body1">
          We may update this Privacy Policy from time to time. Any changes will
          be posted on this page with an updated effective date. We encourage
          you to review this Privacy Policy periodically.
        </Typography>
      </Box>
      <HomepageFooter />
    </>
  );
}

export default PrivacyPolicy;
